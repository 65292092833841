import Head from 'next/head';
import MainLayout from '@components/layouts/MainLayout';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    // Route user to default view
    router.push('/events');
  }, [router]);

  return (
    <>
      <Head>
        <title />
      </Head>
      <main />
    </>
  );
}

Home.getLayout = (page) => (
  <MainLayout>
    {page}
  </MainLayout>
);
